/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
require('typeface-archivo');

if (typeof AbortController === 'undefined') {
  require('abortcontroller-polyfill/dist/polyfill-patch-fetch');
}
if (typeof fetch === 'undefined') {
  require('promise-polyfill/src/polyfill');
  require('whatwg-fetch');
}

require('nodep-date-input-polyfill');

exports.onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    import(`intersection-observer`);
  }
};
